<template>
  <v-row>
    <v-col cols="12">
      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            :label="label"
            name="date_esp"
            :data-vv-as="label"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            v-validate="'required'"
            :disabled="isViewModal"
          ></v-text-field>
          <span class="red--text">{{ errors.first("date_esp") }}</span>
        </template>
        <v-date-picker @change="blur" v-model="fecha" no-title @input="menu1 = false"></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    date: { default: null, type: String },
    initToday: { default: false, type: Boolean },
    label: { default: "Date", type: String },
    name: { default: "date", type: String },
    isRequired: { default: false, type: Boolean },
    isViewModal: { default: false, type: Boolean },
    setDate: { default: null, type: Function },
  },

  data: (vm) => ({
    dateFormatted: null,
    menu1: false,
    fecha: null,
  }),

  mounted() {
    this.init();
  },
  watch: {
    date(val) {
      if (val == null || val == "") {
        this.dateFormatted = null;
        this.errors.clear();
        this.$validator.reset();
      } else {
        this.dateFormatted = this.formatDate(this.date);
      }
    },
  },
  methods: {
    blur() {
      this.$emit("setDate", this.fecha);
      this.dateFormatted = this.formatDate(this.fecha);
    },
    init() {
      if (this.date == null && this.initToday) {
        let date_aux = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        this.dateFormatted = this.formatDate(date_aux);
        this.$emit("setDate", date_aux);
      } else {
        this.dateFormatted = this.formatDate(this.date);
      }
    },
    required() {
      if (this.isRequired) return "required";
      return "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      console.log(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
