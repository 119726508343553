<template>
  <div>
    <Title :pages="[{ icon: 'folder', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        @updateMainItem="updateMainItem"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
        @calendar="calendar"
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <v-row no-gutters>
                <v-col cols="12" md="1" sm="12">
                  <span class="cursor text-filter" style="font-size: 20px" @click="expandFilter()">
                    Filtros
                    <font-awesome-icon icon="filter" />
                  </span>
                </v-col>
                <v-col cols="12" md="10" sm="12" class="text--secondary cursor" @click="expandFilter()">
                  <p class="mb-0 mt-1" v-if="open_filter">Seleccione los filtros deseados</p>
                  <span v-else>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_carpeta_selected == null"> Desde: {{ desde_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_carpeta_selected == null"> Hasta: {{ hasta_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="nro_carpeta_selected != null"> Nº carpeta: {{ nro_carpeta_selected }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="state_selected != null"> Estado: {{ state_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="notary_selected != null"> Escribano: {{ notary_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="folder_manager_selected != null"> Carpetista: {{ folder_manager_selected.name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="seller_selected != null"> Vendedor: {{ seller_selected.business_name }}</v-chip>
                    <v-chip outlined color="#666" class="ml-1 mb-1" v-if="buyer_selected != null"> Comprador: {{ buyer_selected.business_name }}</v-chip>
                  </span>
                </v-col>
                <v-col cols="12" md="1" sm="12" class="dropdown-filter">
                  <font-awesome-icon class="cursor" icon="chevron-down" v-if="!open_filter" @click="open_filter = true" />
                  <font-awesome-icon v-else class="cursor" icon="chevron-up" @click="open_filter = false" />
                </v-col>
              </v-row>
              <hr v-if="open_filter" class="mt-4" />
              <div class="row pt-5" v-if="open_filter">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="nro_carpeta_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Carpeta" clearable></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="notary_selected"
                    autocomplete="off"
                    data-vv-as="notary_selected"
                    name="notary_selected"
                    item-text="name"
                    item-value="id"
                    :items="employees_notary"
                    label="Escribano"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="folder_manager_selected"
                    autocomplete="off"
                    data-vv-as="folder_manager_selected"
                    name="folder_manager_selected"
                    item-text="name"
                    item-value="id"
                    :items="employees_employee"
                    label="Carpetista"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="seller_selected"
                    autocomplete="off"
                    data-vv-as="seller_selected"
                    name="seller_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Vendedor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="buyer_selected"
                    autocomplete="off"
                    data-vv-as="buyer_selected"
                    name="buyer_selected"
                    item-text="business_name"
                    item-value="id"
                    :items="clients"
                    label="Comprador"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-12 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ formatUtils.formatDateString(props.item.open_date) }}</td>
              <td>{{ props.item.notary ? props.item.notary.name : "" }}</td>
              <td>{{ props.item.folder_manager ? props.item.folder_manager.name : "" }}</td>
              <td>{{ props.item.address }}</td>
              <td>
                <v-chip class="mr-1" v-for="obj in props.item.property_buyers" :key="obj.id" small>
                  <span class="truncate" :title="obj.business_name">
                    {{ obj.business_name }}
                  </span>
                </v-chip>
              </td>
              <td>
                <v-chip class="mr-1" v-for="obj in props.item.property_sellers" :key="obj.id" small>
                  <span class="truncate" :title="obj.business_name">
                    {{ obj.business_name }}
                  </span>
                </v-chip>
              </td>
              <td>{{ props.item.signature_date != "" ? formatUtils.formatDateString(props.item.signature_date) : "-" }}</td>
              <td v-html="service.getStateHtml(props.item.state)"></td>
              <td class="text-center" style="min-width: 150px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-2" @click="printPDF(props.item)"> print </v-icon>
                  </template>
                  <span>Imprimir carpeta</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey" class="mr-2" @click="calendar(props.item)"> mdi-calendar </v-icon>
                  </template>
                  <span>Agendar evento</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      <DialogLoading :dialog="dialog_loading" text="Cargando..." />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import { propertySaleService } from "@/libs/ws/propertySaleService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { formatUtils } from "../../libs/utils/formatUtils";
import { generalService } from "@/libs/ws/generalService";
import ConstantEmployeeTypes from "@/constants/employee_types.js";
import Constant from "@/constants/sections";

export default {
  components: { Title, Modal, DialogDelete, DialogLoading },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      formatUtils: formatUtils,
      service: propertySaleService,
      page_title: "Carpetas",
      modal_title: "Carpeta",
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      nro_carpeta_selected: null,
      state_selected: null,
      notary_selected: null,
      folder_manager_selected: null,
      seller_selected: null,
      buyer_selected: null,
      employees_employee: [],
      employees_notary: [],
      states: [],
      clients: [],

      mainItem: {
        id: "",
        open_date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        notary: null,
        folder_manager: null,
        realize: null,
        prepare: null,
        property_buyers: [],
        property_sellers: [],
        property_documents: [],
        property_releases: [],
        address: "",
        amount: "",
        signature_date: "",
        signature_time: "",
        signature_location: "",
        written_sale_folio: "",
        written_sale_numero: "",
        written_mortage_folio: "",
        written_mortage_numero: "",
        observation: "",
      },
      defaultItem: null,
      headers: [
        { text: "Carpeta", value: "id", align: "left" },
        { text: "Fecha de apertura", value: "open_date", align: "left" },
        { text: "Escribano", value: "notary.name", align: "left" },
        { text: "Carpetista", value: "folder_manager.name", align: "left" },
        { text: "Dirección", value: "address", align: "left" },
        { text: "Comprador", value: "", align: "left" },
        { text: "Vendedor", value: "", align: "left" },
        { text: "Fecha de firma", value: "signature_date", align: "left" },
        { text: "Estado", value: "state.name", align: "left" },

        { text: "Accion", value: "", sortable: false, align: "center" },
      ],
      list: [],
      dialog_delete: false,
      dialog_loading: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));

    this.loadList(this.service);
    this.loadComboData();
    this.loadStates();
  },
  methods: {
    calendar(item) {
      if (item.signature_date == "" || item.signature_time == "") {
        this.$toast.error("La carpeta no tiene fecha de firma", "Error");
        return;
      }

      const date = new Date(`${item.signature_date}T${item.signature_time}:00-03:00`);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      const formattedDateTime = `${year}${month}${day}T${hours}${minutes}${seconds}-0300`;

      //envio al usuario a google calendar para crear el evento de firma
      const url =
        "https://calendar.google.com/calendar/render?action=TEMPLATE&text=Firma Carpeta Nº" +
        item.id +
        "&dates=" +
        formattedDateTime +
        "/" +
        formattedDateTime +
        "&details=Evento de firma de la carpeta Nº" +
        item.id +
        "&location=" +
        item.signature_location +
        "&sf=true&output=xml";
      window.open(url, "_blank");
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        notary: this.notary_selected,
        folder_manager: this.folder_manager_selected,
        state: this.state_selected,
        nro_carpeta: this.nro_carpeta_selected,
        seller: this.seller_selected,
        buyer: this.buyer_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadStates() {
      this.service
        .states()
        .then((response) => {
          this.states = response.result;
        })
        .catch((e) => console.log("error states property sale", e));
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });

      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_EMPLOYEE }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees_employee = response.result;
      });

      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_NOTARY }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees_notary = response.result;
      });
    },
    printPDF(item) {
      if (this.dialog_loading) return;
      this.dialog_loading = true;

      this.service
        .printPDF(item.id)
        .then((response) => {
          if (!generalService.downloadPdf(response, "carpeta_" + item.id + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((e) => {
          console.log("service.print error");
          console.log(e);
        })
        .finally(() => {
          this.dialog_loading = false;
        });
    },
    updateMainItem() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
  },
};
</script>
