<template>
  <v-navigation-drawer
    :width="240"
    :permanent="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
    dark
    app
    class="rounded-0"
    v-model="$store.state.sidebar.open"
    :mini-variant.sync="$store.state.sidebar.mini"
    :expand-on-hover="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs && $store.state.sidebar.expand_on_hover"
  >
    <v-card @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" height="100vh" width="240">
      <div>
        <v-list style="height: 100px">
          <v-list-item>
            <v-list-item-content class="pt-1" style="display: flex; justify-content: center">
              <v-img v-show="expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" contain style="max-width: 75px" class="pt-0" />

              <v-img v-show="!expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" contain height="70" class="pt-8" />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="mt-0"></v-divider>
      </div>

      <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
        <v-list v-if="item.visible" dense nav>
          <v-list-group v-if="item.visible && 'items' in item && item.items.length > 0" v-model="item.active" :key="item.title" no-action>
            <v-list-item slot="activator">
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
              <v-list-item v-if="subItem.visible" ripple @click="close" class="subitem">
                <v-list-item-icon>
                  <font-awesome-icon :icon="subItem.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link v-else-if="item.visible" :to="'/' + item.path">
            <v-list-item :key="item.title" link>
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import Constant from "@/constants/sections";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
export default {
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      drawer: false,
      mini: true,
      isExternal: null,
      allItems: [
        { title: "Monitoreo", icon: "desktop", path: Constant.SECTION_MONITORING, visible: false },
        {
          title: "Certificaciones",
          icon: "file-invoice",
          active: false,
          path: Constant.SECTION_CERTIFICATION_ORDERS,
          visible: false,
        },
        {
          title: "Carpetas",
          icon: "folder",
          active: false,
          path: Constant.SECTION_PROPERTY_SALES,
          visible: false,
        },
        { title: "Remitos", icon: "file-invoice-dollar", path: Constant.SECTION_REMITOS, visible: false },
        { title: "Clientes", icon: "users", path: Constant.SECTION_CLIENTS, visible: false },
        { title: "Solicitantes", icon: "person", path: Constant.SECTION_APPLICANTS, visible: false },
        { title: "Personerías", icon: "file-powerpoint", path: Constant.SECTION_PERSONALITIES, visible: false },
        { title: "Firmantes", icon: "signature", path: Constant.SECTION_SIGNATORIES, visible: false },
        { title: "Cargos", icon: "users-between-lines", path: Constant.SECTION_CARGOS, visible: false },
        {
          title: "Facturacion",
          icon: "dollar",
          active: false,
          visible: false,
          items: [
            { title: "Emisores", icon: "inbox", path: Constant.SECTION_EMITTERS, visible: false },
            { title: "Receptores", icon: "inbox", path: Constant.SECTION_RECEIVERS, visible: false },
            { title: "Comprobantes", icon: "file", path: Constant.SECTION_VOUCHERS, visible: false },
          ],
        },
        {
          title: "Administrar",
          icon: "cog",
          active: false,
          visible: false,
          items: [
            { title: "Tareas", icon: "list-check", path: Constant.SECTION_TASKS, visible: false },
            { title: "Armarios", icon: "location", path: Constant.SECTION_CUPBOARD, visible: false },
            { title: "Libros", icon: "book", path: Constant.SECTION_LIBROS, visible: false },
            { title: "Empleados", icon: "user-tie", path: Constant.SECTION_EMPLOYEES, visible: false },
            { title: "Roles", icon: "user-cog", path: Constant.SECTION_ROLES, visible: false },
          ],
        },
      ],
      items: [],
      right: null,
      expandMenu: this.$store.state.sidebar.mini ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();
  },
  watch: {},
  methods: {
    isPermanent() {
      console.log(window.innerWidth);
      return window.innerWidth >= 800 && this.$store.state.sidebar.mini;
    },
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.mini) {
        this.$store.commit("setOpenSidebar", {
          expand_on_hover: true,
        });
      }
    },
    close() {},
    loadSideBarExternal() {
      this.items = this.getItemsAvailable();
    },
    getItemsAvailable() {
      let itemsAvailable = [...this.allItems];
      //console.log("this.roles",this.roles)
      itemsAvailable.forEach((item) => {
        if ("items" in item) {
          let any_visible = false;
          item.items.forEach((sub_item) => {
            sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
            if (sub_item.visible) any_visible = true;
          });
          item.visible = any_visible;
        } else {
          item.visible = item.path in this.roles && this.roles[item.path].sidebar;
        }
      });
      return itemsAvailable;
    },
  },
};
</script>
