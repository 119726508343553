const SECRET_PHRASE = "Ingen1@r*urriesti";

const SECTION_ROLES = "roles";
const SECTION_EMPLOYEES = "employees";

const SECTION_CLIENTS = "clients";
const SECTION_APPLICANTS = "applicants";
const SECTION_CARGOS = "cargos";
const SECTION_SIGNATORIES = "signatories";
const SECTION_PERSONALITIES = "personalities";

const SECTION_TASKS = "tasks_names";
const SECTION_ORDERS_STATES = "orders_states";

const SECTION_CERTIFICATION_ORDERS = "certification_orders";
const SECTION_ASSIGNATED_TASK = "assigned_task";

const SECTION_STORAGE = "storage";

const SECTION_MONITORING = "monitoring";

const SECTION_REMITOS = "remitos";

//Facturacion
const SECTION_LIST_DOC_TYPES = "billing/list_doc_types";
const SECTION_LIST_IVA_CONDITIONS_EMITTER = "billing/list_iva_conditions_emitter";
const SECTION_LIST_IVA_CONDITIONS = "billing/list_iva_conditions";
const SECTION_LIST_STATES = "billing/list_states";
const SECTION_CBTE_TIPOS = "billing/cbte_tipos";
const SECTION_EMITTERS = "emitters";
const SECTION_RECEIVERS = "receivers";

const SECTION_VOUCHERS = "vouchers";

const SECTION_CUPBOARD = "cupboard";

const SECTION_LIBROS = "libros";

const SECTION_PROPERTY_SALES = "property_sales";

export default { 
    SECRET_PHRASE: SECRET_PHRASE,

    SECTION_ROLES: SECTION_ROLES,
    SECTION_EMPLOYEES: SECTION_EMPLOYEES,        

    SECTION_CLIENTS: SECTION_CLIENTS,
    SECTION_APPLICANTS: SECTION_APPLICANTS,    
    SECTION_CARGOS: SECTION_CARGOS,
    SECTION_SIGNATORIES: SECTION_SIGNATORIES,
    SECTION_PERSONALITIES: SECTION_PERSONALITIES,
        
    SECTION_TASKS: SECTION_TASKS,
    SECTION_ORDERS_STATES: SECTION_ORDERS_STATES, 
    
    SECTION_CERTIFICATION_ORDERS: SECTION_CERTIFICATION_ORDERS,
    SECTION_ASSIGNATED_TASK: SECTION_ASSIGNATED_TASK,

    SECTION_STORAGE: SECTION_STORAGE,

    SECTION_MONITORING: SECTION_MONITORING,

    SECTION_REMITOS: SECTION_REMITOS,

    SECTION_EMITTERS: SECTION_EMITTERS,
    SECTION_RECEIVERS: SECTION_RECEIVERS,
    SECTION_LIST_DOC_TYPES: SECTION_LIST_DOC_TYPES,
    SECTION_LIST_IVA_CONDITIONS_EMITTER: SECTION_LIST_IVA_CONDITIONS_EMITTER,
    SECTION_LIST_IVA_CONDITIONS: SECTION_LIST_IVA_CONDITIONS,
    SECTION_LIST_STATES: SECTION_LIST_STATES,
    SECTION_CBTE_TIPOS: SECTION_CBTE_TIPOS,

    SECTION_VOUCHERS: SECTION_VOUCHERS,

    SECTION_CUPBOARD: SECTION_CUPBOARD,
    SECTION_PROPERTY_SALES: SECTION_PROPERTY_SALES,
    SECTION_LIBROS: SECTION_LIBROS,
};
