function formatPrice(price) {
  if (price == null) return "-";
  price = parseFloat(price).toFixed(2);
  return "$" + new Intl.NumberFormat("es-AR", {}).format(price);
}

function formatPriceNumber(price) {
  if (price == null) return "-";
  return parseFloat(parseFloat(price).toFixed(2));
}

function formatDateString(date_string) {
  //para que tengo el uso horario de ARG
  date_string = date_string + " " + "03:00:00";
  //Format "yyyy-mm-dd" to "dd/mm/yyyy"
  if (date_string === null) return "-";
  const date = new Date(date_string);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const formatUtils = {
  formatPrice,
  formatPriceNumber,
  formatDateString,
};
