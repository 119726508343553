import { wsService } from "./wsService";

var section = "";

function filter(param = {}, path = section) {  
  return wsService.make_post_request(path + "/list", param);
}

function get(id, path = section) {  
  return wsService.make_get_request(path + "/get/" + id);
}

function create(param = {}, path = section) {
  return wsService.make_post_request(path + "/create", param);
}

function update(param = {}, path = section) {
  return wsService.make_put_request(path + "/update", param);
}

function update_wfile(param = {}, path = section) {
  return wsService.make_post_request(path + "/update", param);
}

function remove(param = {}, path = section) {
  return wsService.make_delete_request(path + "/delete", param);
}

function customPost(param = {}, path = section, custom_path = "") {
  return wsService.make_post_request(path + "/"+custom_path, param);
}

function setSection(s) {  
  section = s;  
}

function downloadPdf(response,filename, store_to_zip = false) {
  if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
    return false;
  }

  var blobFile = new Blob([response], {type: "application/pdf"});
  let link = document.createElement("a");          
  link.download = filename;
  link.href = window.URL.createObjectURL(blobFile);
  link.target = "_blank";

  if (store_to_zip) {
    const file = {
        file: blobFile,
        filename: filename
    }
    console.log("file generalService",file)
    return file;    
  }else{    
    link.click();  
  }
  return true;
}

export const generalService = { 
  section,
  setSection, 
  filter,
  get,
  remove,
  create,
  update,
  update_wfile,
  customPost,

  downloadPdf,
};