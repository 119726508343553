<template>
  <div>
    <ModalQuickLoad :dialog="dialog_quick_load" @closeDialog="dialog_quick_load = false" @processQuickLoad="processQuickLoad" />
    <ModalAddSignatory :dialog="dialog_add_signatory" :item="asignatedTaskItem" :client="client" @closeDialog="dialog_add_signatory = false" />
    <v-dialog v-model="dialog" @click:outside="closeModalTask()" @keydown.esc="closeModalTask()" v-on:input="closeModalTask()" transition="dialog-bottom-transition" max-width="800">
      <v-form v-on:submit.prevent="addTask">
        <v-card>
          <v-card-title class="headline">Agregar tarea</v-card-title>

          <v-card-text style="max-width: 600px; margin: 0 auto" class="mt-5">
            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-combobox
                  dense
                  v-model="asignatedTaskItem.task"
                  data-vv-as="tarea"
                  name="tarea"
                  item-text="name"
                  item-value="id"
                  :items="tasks"
                  label="Tarea*"
                  autocomplete="off"
                  v-validate="'required'"
                  v-on:change="taskChangeCombo($event)"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("tarea") }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field dense data-vv-as="legalizacion" label="Legalización" name="legalizacion" v-model="asignatedTaskItem.legalizacion" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("legalizacion") }}</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field dense data-vv-as="apostilla" label="Apostilla" name="legalizacion" v-model="asignatedTaskItem.apostilla" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("apostilla") }}</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field dense data-vv-as="apostilla_urgente" label="Apostilla Urgente" name="legalizacion" v-model="asignatedTaskItem.apostilla_urgente" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("apostilla_urgente") }}</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field dense data-vv-as="anexo" label="Anexo" name="legalizacion" v-model="asignatedTaskItem.anexo" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("anexo") }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6"
                ><v-combobox
                  dense
                  v-model="asignatedTaskItem.cargo"
                  data-vv-as="cargo"
                  name="cargo"
                  item-text="name"
                  item-value="id"
                  :items="cargos"
                  label="Cargo"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("cargo") }}</span>
              </v-col>
              <v-col cols="12" md="6">
                {{/*ONLY EMPLOYEES*/}}
                <v-combobox
                  dense
                  v-model="asignatedTaskItem.notary"
                  data-vv-as="notary"
                  name="notary"
                  item-text="name"
                  item-value="id"
                  :items="notaryes"
                  label="Escribano"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("notary") }}</span>
              </v-col>
            </v-row>

            <div>
              <hr class="mt-4" />
              <v-row>
                <v-col md="12">
                  <p class="mt-5 mb-5 fs-16 float-left">
                    <b>Actas / Libros / Fojas</b>

                    <v-btn small color="blue-grey" class="ml-5 text-white" @click="addNrDetail()">
                      <v-icon big color="green"> add </v-icon>
                      <span>1 Acta</span>
                    </v-btn>

                    <v-btn small color="blue-grey" class="ml-3 text-white" @click="openQuickLoad()">
                      <v-icon big color="orange"> bolt </v-icon>
                      <span>Varias actas</span>
                    </v-btn>
                  </p>
                </v-col>
              </v-row>

              <v-data-table class="pb-4" :headers="header_details" :items="this.asignatedTaskItem.details" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                <template v-slot:item="props">
                  <tr>
                    <td>
                      <v-text-field autocomplete="off" name="acta" placeholder="Nº Acta" dense v-validate="'decimal'" type="number" v-model="props.item.acta" class="mt-3" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("acta") }}</span>
                    </td>
                    <td>
                      <v-text-field
                        autocomplete="off"
                        name="libro"
                        placeholder="Nº Libro"
                        dense
                        v-validate="'decimal'"
                        type="number"
                        v-model="props.item.libro"
                        class="mt-3"
                        :disabled="isViewModal()"
                      />
                      <span class="red--text">{{ errors.first("libro") }}</span>
                    </td>
                    <td>
                      <v-text-field autocomplete="off" name="foja" placeholder="Nº Foja" dense type="text" v-model="props.item.foja" class="mt-3" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("foja") }}</span>
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom v-if="!isViewModal()">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" @click="removeDetail(props.item)"> indeterminate_check_box </v-icon>
                        </template>
                        <span>Remover</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <hr class="mt-4" />

            <div class="row mt-0">
              <div class="col-md-12">
                <label class="fs-16">¿Desea adjuntar documentos?*</label>
                <v-radio-group v-model="asignatedTaskItem.has_attached_files" v-validate="'required'" data-vv-as="¿Desea adjuntar documentos?" name="has_attached_files">
                  <v-radio v-for="obj in yesno_group" :key="obj.id" :label="obj.name" :value="obj.id" :disabled="isViewModal()" />
                </v-radio-group>
                <span class="red--text">{{ errors.first("has_attached_files") }}</span>
              </div>
            </div>

            <v-row v-if="asignatedTaskItem.has_attached_files != null">
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  data-vv-as="documents_size"
                  label="Indique la cantidad de documentos de la tarea"
                  name="documents_size"
                  v-model="documents_size"
                  v-validate="'integer|min:0'"
                  :disabled="isViewModal()"
                />
                <span class="red--text">{{ errors.first("documents_size") }}</span>
              </v-col>
            </v-row>

            <div v-if="asignatedTaskItem.has_attached_files != null">
              <hr class="mt-4" />
              <v-row>
                <v-col md="12">
                  <p class="mt-5 mb-5 fs-16 float-left"><b>Documentos</b></p>
                </v-col>
              </v-row>

              <v-data-table
                class="pb-4"
                :headers="asignatedTaskItem.task != null && asignatedTaskItem.task.criteria_price.id == 'H' ? headers_documents_w_pages : headers_documents_w_signatures"
                :items="this.asignatedTaskItem.documents"
                style="border: solid 1px #1c1c1c"
                :hide-default-footer="true"
                :items-per-page="100"
              >
                <template v-slot:item="props">
                  <tr style="height: 100px">
                    <td>
                      {{ props.index + 1 }}
                    </td>
                    <td>
                      <div v-if="asignatedTaskItem.has_attached_files != null && asignatedTaskItem.has_attached_files === true">
                        <v-file-input
                          :key="props.index"
                          dense
                          truncate-length="15"
                          name="document"
                          accept="application/pdf"
                          v-model="props.item.file_attached"
                          @change="onDocumentsChange(props.index)"
                          label="Seleccione el documento"
                          :disabled="isViewModal()"
                          v-validate="'required'"
                        ></v-file-input>
                        <span class="red--text">{{ errors.first("document") }}</span>
                      </div>
                      <div v-else>
                        {{ props.item.name }}
                      </div>
                    </td>
                    <td v-if="asignatedTaskItem.task != null && asignatedTaskItem.task.criteria_price.id == 'H'">
                      <v-text-field autocomplete="off" name="pages" dense v-validate="'decimal'" type="number" v-model="props.item.pages" class="mt-3" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("pages") }}</span>
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom v-if="!isViewModal()">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" @click="removeDocument(props.item)"> indeterminate_check_box </v-icon>
                        </template>
                        <span>Remover</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <div v-if="asignatedTaskItem.task != null && asignatedTaskItem.task.has_signatories">
              <hr class="mt-4" />
              <v-row>
                <v-col md="12">
                  <p class="mt-5 mb-5 fs-16 float-left"><b>Firmantes</b></p>

                  <v-btn color="primary" class="mt-4 ml-3" dark small @click="dialog_add_signatory = true" v-if="!isViewModal()">+ Agregar firmante</v-btn>
                </v-col>
              </v-row>

              <v-data-table class="pb-4" :headers="headers_signatoryes" :items="asignatedTaskItem.signatories" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                <template v-slot:item="props">
                  <tr>
                    <td>
                      {{ props.index + 1 }}
                    </td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.dni }}</td>
                    <td class="text-center">
                      <v-tooltip bottom v-if="!isViewModal()">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" @click="removeSignatory(props.item)"> indeterminate_check_box </v-icon>
                        </template>
                        <span>Remover</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <hr class="pt-4" />
            <div class="row">
              <div class="col-md-12">
                <label class="fs-16"><b>Prioridad</b></label>
                <p class="subtext">Marque la prioridad que requiere la tarea</p>
                <v-radio-group v-model="asignatedTaskItem.priority">
                  <v-radio v-for="obj in priority_group" :key="obj.id" :label="obj.name" :value="obj.id" :disabled="isViewModal()"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeModalTask()">Cerrar</v-btn>
              <v-btn color="success" :loading="loading" v-if="!isViewModal()" text type="submit">{{ asignatedTaskItemEdit == null ? "Agregar" : "Editar" }}</v-btn>
            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import ConstantTaskPriceBased from "@/constants/task_price_based.js";
import ConstantEmployeeTypes from "@/constants/employee_types.js";
import { generalService } from "@/libs/ws/generalService";
import ModalAddSignatory from "./ModalAddSignatory.vue";
import ModalQuickLoad from "./ModalQuickLoad.vue";
import { PDFDocument } from "pdf-lib";
import { fileUtils } from "@/libs/utils/fileUtils";
const fs = require("fs");
export default {
  props: {
    dialog: Boolean,
    item: Object,
    client: Object,
    asignatedTaskItemEdit: Object,
    editAssignatedTasks: Function,
    closeDialog: Function,
    viewdIndex: Number,
    editedIndex: Number,
  },
  components: { ModalAddSignatory, ModalQuickLoad },
  watch: {
    client: function (newVal, oldVal) {
      if (this.client != null && this.client.id > 0) {
        this.clientChangeCombo();
      }
    },
    dialog: {
      handler(newVal) {
        if (this.dialog) {
          this.initialize();
        }
      },
      deep: true,
    },
    documents_size(value) {
      this.onDocumentSizeInputChange();
    },
    "asignatedTaskItem.has_attached_files": {
      handler(newVal) {
        this.reinitializeDocuemnts();
      },
      deep: true,
    },
  },
  data: () => ({
    loading: false,
    tasks: [],
    notaryes: [],
    cargos: [],
    yesno_group: [
      { id: true, name: "Si" },
      { id: false, name: "No" },
    ],
    priority_group: [
      { id: 1, name: "Baja" },
      { id: 2, name: "Media" },
      { id: 3, name: "Alta" },
    ],
    headers_signatoryes: [
      { text: "Número", value: "number", sortable: false, class: "bg-grey" },
      { text: "Nombre", value: "name", sortable: false, class: "bg-grey" },
      { text: "DNI", value: "dni", sortable: false, class: "bg-grey" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    headers_documents_w_signatures: [
      { text: "Número", value: "number", sortable: false, class: "bg-grey", width: "5%" },
      { text: "Nombre de archivo", value: "name", sortable: false, class: "bg-grey" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    headers_documents_w_pages: [
      { text: "Número", value: "number", sortable: false, class: "bg-grey", width: "5%" },
      { text: "Nombre de archivo", value: "name", sortable: false, class: "bg-grey" },
      { text: "Cantidad de hojas", value: "pages", sortable: false, class: "bg-grey", width: "5%" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    header_details: [
      { text: "Acta", value: "acta", sortable: false, class: "bg-grey" },
      { text: "Libro", value: "libro", sortable: false, class: "bg-grey" },
      { text: "Foja", value: "foja", sortable: false, class: "bg-grey" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    show_pages: false,
    dialog_add_signatory: false,
    defaultAsignatedTaskItem: null,
    documents_size: 0,
    asignatedTaskItem: {
      id: null,
      cargo: null,
      task: null, //{ id: null, name: "", criteria_price: "", has_anexo: false },
      notary: null,
      priority: 2,
      has_attached_files: null,
      legalizacion: 0,
      apostilla: 0,
      apostilla_urgente: 0,
      anexo: 0,
      signatories: [],
      documents: [],
      details: [],
    },
    files: [],
    dialog_quick_load: false,
  }),
  mounted() {
    this.loadComboData();
    this.defaultAsignatedTaskItem = JSON.parse(JSON.stringify(this.asignatedTaskItem));
  },
  methods: {
    isViewModal() {
      return this.viewdIndex >= 0;
    },
    initialize() {
      if (this.asignatedTaskItemEdit != null) {
        this.asignatedTaskItem = JSON.parse(JSON.stringify(this.asignatedTaskItemEdit));
        this.asignatedTaskItem.documents = JSON.parse(JSON.stringify(this.asignatedTaskItemEdit.documents));
        this.documents_size = this.asignatedTaskItem.documents.length;
      }
    },
    getDocumentTemplate(file, name, pages = 0) {
      return {
        id: null,
        file_attached: file,
        file: null,
        name: name,
        pages: pages,
      };
    },
    closeModalTask() {
      this.asignatedTaskItem = JSON.parse(JSON.stringify(this.defaultAsignatedTaskItem));
      this.$validator.reset();
      this.$emit("closeDialog");
    },
    loadComboData() {
      generalService.filter({}, ConstantSection.SECTION_TASKS).then((response) => {
        this.tasks = response.result;
      });
      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_NOTARY }, ConstantSection.SECTION_EMPLOYEES).then((response) => {
        this.notaryes = response.result;
      });
    },
    clientChangeCombo() {
      if (this.client != null && this.client.id > 0) {
        generalService.filter({ client: this.client.id }, ConstantSection.SECTION_CARGOS).then((response) => {
          this.cargos = response.result;
        });
      }
    },
    cleanArrayCollection(array_collection) {
      //Limpiar array documents y signatoryes sin eliminar los que tengan ids
      array_collection.forEach((item) => {
        if (item.id == null) {
          const index = array_collection.indexOf(item);
          if (index > -1) array_collection.splice(index, 1);
        }
      });
    },
    async onDocumentsChange(index) {
      if (this.asignatedTaskItem.has_attached_files != null && this.asignatedTaskItem.has_attached_files === true) {
        console.log("xx", this.asignatedTaskItem.documents[index].file_attached);
        this.asignatedTaskItem.documents[index].name = this.asignatedTaskItem.documents[index].file_attached.name;
        this.getPagePdf(this.asignatedTaskItem.documents[index].file_attached, index);
        const b64file = await fileUtils.toBase64(this.asignatedTaskItem.documents[index].file_attached);
        this.asignatedTaskItem.documents[index].file = b64file;
      }
    },
    async getPagePdf(file, index) {
      const currentComponent = this;
      var fileReader = new FileReader();
      fileReader.onload = async function () {
        var typedarray = new Uint8Array(this.result);
        const pdfDoc = await PDFDocument.load(typedarray);
        const pages = pdfDoc.getPages();
        currentComponent.asignatedTaskItem.documents[index].pages = Math.ceil(pages.length / 2); //doblefaz
      };
      fileReader.readAsArrayBuffer(file);
    },
    taskChangeCombo(task_selected) {
      this.show_pages = this.asignatedTaskItem.task.follow_price_based == ConstantTaskPriceBased.PRICE_BASED_PAGES;
      if (!this.show_pages) this.asignatedTaskItem.documents.pages = null;
    },
    removeSignatory(signatory) {
      const index = this.asignatedTaskItem.signatories.indexOf(signatory);
      if (index > -1) {
        this.asignatedTaskItem.signatories.splice(index, 1);
      }
    },
    removeDocument(document) {
      const index_file = this.files.indexOf(document.file);
      const index_document = this.asignatedTaskItem.documents.indexOf(document);

      if (index_file > -1) this.files.splice(index_file, 1);
      if (index_document > -1) this.asignatedTaskItem.documents.splice(index_document, 1);
      this.documents_size = this.asignatedTaskItem.documents.length;
    },
    removeDetail(item) {
      const index_item = this.asignatedTaskItem.details.indexOf(item);
      if (index_item > -1) this.asignatedTaskItem.details.splice(index_item, 1);
    },
    async customValidate(){
      return true;
      /*
      //Validar si tiene actas, debe tener escribano
      if(this.asignatedTaskItem.details.length > 0 && (this.asignatedTaskItem.notary == null || !(this.asignatedTaskItem.notary.id > 0))){
        this.$toast.error("Debe seleccionar un escribano para especificar Actas/Libros/Fojas.", "Error");
        return false;
      }

      //Validar acta vs foja ultimos caracteres
      let detailActa = true;
      for await (const item of this.asignatedTaskItem.details) {
        const acta_length = item.acta.length;     
        console.log("acta_length", acta_length);   
        const foja_acta = item.foja.toString().slice(-acta_length);
        console.log("foja_acta", foja_acta);
        console.log(parseInt(foja_acta), parseInt(item.acta), parseInt(foja_acta) != parseInt(item.acta));
        if(parseInt(foja_acta) != parseInt(item.acta)){
          this.$toast.error("El número de foja ("+item.foja+") debe coincidir con el número del acta ("+item.acta+").", "Error");
          detailActa = detailActa && false;
        }
      }

      if(!detailActa){
        console.log("detailActa", detailActa);
        return detailActa;
      }

      //Validar libro contra Escribano request
      if(this.asignatedTaskItem.notary != null && this.asignatedTaskItem.notary.id > 0 && this.asignatedTaskItem.details.length > 0){
        let detailResult = true;
        for await (const item of this.asignatedTaskItem.details) {
          let params = {
            libro: item.libro,
            notary: this.asignatedTaskItem.notary.id,
          }

          let libro_response = await generalService.customPost(params, ConstantSection.SECTION_LIBROS, "list");
          if(libro_response.result.length == 0 || libro_response.result.length > 1){
            this.$toast.error("El libro "+item.libro+" no se encuentra registrado para el escribano seleccionado.", "Error");
            detailResult = detailResult && false;
          }

          let libro = libro_response.result[0];
          //Controlar que item.foja sea mayor o igual a libro.foja_desde y menor o igual a libro.foja_hasta
          console.log("libro", libro);
          if(parseInt(item.foja) < parseInt(libro.foja_desde) || parseInt(item.foja) > parseInt(libro.foja_hasta)){
            this.$toast.error("El número de foja "+item.foja+" no se encuentra registrado para el libro "+item.libro+".", "Error");
            detailResult = detailResult && false;
          }
        }

        console.log("detailResult", detailResult);
        if(!detailResult){
          return detailResult;
        }
      }

      console.log("customValidate last return", true);
      return true;
      */
    },    
    async addTask(e) {
      this.$validator.validate().then(async(valid) => {
        if (valid) {

          this.loading = true;
          try{
            const customValidateResult = await this.customValidate(); 
            console.log("customValidateResult", customValidateResult);         
            if(!customValidateResult){
              this.loading = false;
              return;
            }
            this.loading = false;
          }catch(e){
            console.log("customValidate error", e);
            this.loading = false;
            return;
          }

          e.preventDefault();
          if (this.asignatedTaskItemEdit != null) {
            const index = this.item.asignated_tasks.indexOf(this.asignatedTaskItemEdit);
            if (index > -1) {
              this.$emit("editAssignatedTasks", index, this.asignatedTaskItem);
            } else {
              this.$toast.error("No se encontro la tarea asignada.", "Error");
              return;
            }
          } else {
            this.item.asignated_tasks.push(this.asignatedTaskItem);
          }
          this.closeModalTask();
        } else {
          console.log("valid", valid);
          console.log("errors", this.errors);
        }
      });
    },
    reinitializeDocuemnts() {
      this.files = [];
      if (this.asignatedTaskItemEdit != null) {
        this.asignatedTaskItem.documents = JSON.parse(JSON.stringify(this.asignatedTaskItemEdit.documents));
      } else {
        this.asignatedTaskItem.documents = [];
      }
      this.documents_size = this.asignatedTaskItem.documents.length;
    },
    onDocumentSizeInputChange() {
      if (this.documents_size > 0) {
        const currentComponent = this;
        for (var i = this.asignatedTaskItem.documents.length + 1; i <= this.documents_size; i++) {
          //this.files.push(i);
          currentComponent.asignatedTaskItem.documents.push(this.getDocumentTemplate(null, "Archivo " + i, 0));
        }
      } else {
        this.files = [];
        this.asignatedTaskItem.documents = [];
      }
    },
    addNrDetail() {
      if(this.asignatedTaskItem.notary == null || !(this.asignatedTaskItem.notary.id > 0)){
        this.$toast.error("Debe seleccionar un escribano.", "Error");
        return;
      }

      this.asignatedTaskItem.details.push({
        acta: "",
        libro: "",
        foja: "",
      });
    },
    openQuickLoad() {
      if(this.asignatedTaskItem.notary == null || !(this.asignatedTaskItem.notary.id > 0)){
        this.$toast.error("Debe seleccionar un escribano.", "Error");
        return;
      }

      this.dialog_quick_load = true;
    },
    processQuickLoad(param, max_diff) {
      const foja_desde_int = parseInt(param.foja_desde.substring(1));
      const foja_hasta_int = parseInt(param.foja_hasta.substring(1));
      for (let item = 0; item <= max_diff; item++) {
        //3
        let nr_detail = {
          acta: "",
          libro: "",
          foja: "",
        };

        if (parseInt(param.acta_desde) + item > parseInt(param.acta_hasta)) nr_detail.acta = parseInt(param.acta_hasta);
        else nr_detail.acta = parseInt(param.acta_desde) + item;

        if (parseInt(param.libro_desde) + item > parseInt(param.libro_hasta)) nr_detail.libro = parseInt(param.libro_hasta);
        else nr_detail.libro = parseInt(param.libro_desde) + item;

        if (foja_desde_int + item > foja_hasta_int) nr_detail.foja = "F" + foja_hasta_int;
        else nr_detail.foja = "F" + (foja_desde_int + item);
        this.asignatedTaskItem.details.push(nr_detail);
      }
    },
  },
};
</script>
