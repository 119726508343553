import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'book', page: _vm.page_title.toUpperCase() }]}}),_c(VContainer,{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"page_title":_vm.page_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){return _vm.loadList(_vm.service)},"updateItem":function (newItem) {
          _vm.mainItem = newItem;
        }}}):_vm._e(),(_vm.list_access)?_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.page_title))]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.notary.name))]),_c('td',[_vm._v(_vm._s(props.item.libro))]),_c('td',[_vm._v(_vm._s(props.item.foja_desde))]),_c('td',[_vm._v(_vm._s(props.item.foja_hasta))]),_c('td',{staticClass:"justify-content-center layout px-0"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.update_access)?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewItem(props.item)}}},on),[_vm._v(" remove_red_eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.delete_access)?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(props.item)}}},on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])]}},{key:"no-results",fn:function(){return [_c(VAlert,{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,2721323706)})],1):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }