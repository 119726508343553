<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)"  max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">

                <v-combobox                  
                  v-model="item.notary"
                  data-vv-as="escribano"
                  name="notary"
                  item-text="name"
                  item-value="id"
                  :items="notaryes"
                  label="Escribano*"
                  autocomplete="off"
                  :disabled="isViewModal()"
                  clearable
                ></v-combobox>
                <span class="red--text">{{ errors.first("notary") }}</span>

                <v-text-field data-vv-as="libro" label="Nº Libro*" name="libro" type="numeric" v-model="item.libro" v-validate="'required|numeric|min_value:1'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("libro") }}</span>

                <v-text-field data-vv-as="foja desde" label="Foja Desde*" name="foja_desde" type="numeric" v-model="item.foja_desde" v-validate="'required|numeric|min_value:1'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("foja_desde") }}</span>

                <v-text-field data-vv-as="foja hasta" label="Foja Hasta*" name="foja_hasta" type="numeric" v-model="item.foja_hasta" v-validate="'required|numeric|min_value:1'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("foja_hasta") }}</span>
                
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ConstantEmployeeTypes from "@/constants/employee_types";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    page_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,      
      notaryes: [],
      loading: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_NOTARY }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.notaryes = response.result;
      });
    },
    customValidate(){
      //Validar foja_desde < foja_hasta
      console.log("item",this.item);
      if(parseInt(this.item.foja_desde) > parseInt(this.item.foja_hasta)){
        this.$toast.error("La foja desde no puede ser mayor a la foja hasta", "Error");
        return false;
      }
      return true;
    },
    createItem(e) {
      if(this.loading){
        return;
      }

      try{
        this.$validator.validate().then((valid) => {
          if (valid) {          
            e.preventDefault(); 
            
            if(this.customValidate() == false){
              return;
            }                                  

            this.loading = true;
            if (this.item.id > 0) {
              this.service
                .update(this.item)
                .then((response) => {
                  if (response.status == true) {
                    this.$toast.success("El "+this.page_title+" se modifico correctamente.", "OK");
                    this.$emit("updateDialog", false);
                    this.$emit("loadList");
                  } else {
                    if(Object.prototype.hasOwnProperty.call(response, 'message')){
                      this.$toast.error(response.message, "Error");
                    }else{
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                })
                .catch((response) => {                
                  this.$toast.error(response.msg, "Error");
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {            
              this.service
                .create(this.item)
                .then((response) => {                  
                  if (response.status == true && response.result.id > 0) {
                    this.$toast.success("El "+this.page_title+" se creo correctamente", "OK");
                    this.$emit("updateDialog", false);
                    this.$emit("loadList");
                  } else{                  
                    if(Object.prototype.hasOwnProperty.call(response, 'message')){
                      this.$toast.error(response.message, "Error");
                    }else{
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                })
                .catch((response) => {              
                  this.$toast.error(response.msg, "Error");
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          }
        });
      }catch(e){
        this.$toast.error("Error al guardar el "+this.page_title, "Error");
      }finally{
        this.loading = false;
      }
    },
  },
};
</script>
