<template>
  <div>
    <v-data-table :headers="headers" :items="list" :loading="loading" loading-text="Cargando..." hide-default-footer>
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.release_type.name }}</td>
          <td>
            <v-icon v-if="props.item.done === true" small color="green">check</v-icon>
            <v-icon v-if="props.item.done === false" small color="red">close</v-icon>
          </td>
          <td>{{ props.item.done_date }}</td>
          <td class="text-center">
            <v-tooltip bottom v-if="!isViewModal">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog_edit" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ editedItem.release_type.name }}</span>
        </v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <v-checkbox v-model="editedItem.done" label="Realizo"></v-checkbox>
            </div>
            <div class="col-md-6">
              <DatePickerEsp
                label="Fecha"
                :date="editedItem.done_date"
                name="done_date"
                @setDate="
                  (date) => {
                    editedItem.done_date = date;
                  }
                "
                class="pt-0"
                :initToday="true"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeEdit">Cancelar</v-btn>
          <v-btn color="success" text @click="saveEdit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { propertySaleService } from "@/libs/ws/propertySaleService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  props: {
    list: Array,
    loading: Boolean,
    updateRelease: Function,
    isViewModal: Boolean,
  },
  components: {
    DatePickerEsp,
  },
  data() {
    return {
      service: propertySaleService,
      headers: [
        { text: "Documento", value: "release_type.name", align: "left" },
        { text: "Realizo", value: "done", align: "left" },
        { text: "Fecha", value: "done_date", align: "left" },
        { text: "Accion", value: "", sortable: false, align: "center" },
      ],
      dialog_delete: false,
      editedItem: {
        done: false,
        done_date: null,
        release_type: { name: "" },
        id: null,
      },
      defaultItem: {
        done: false,
        done_date: null,
        release_type: { name: "" },
        id: null,
      },
      dialog_edit: false,
    };
  },
  mounted() {},
  methods: {
    editItem(item) {
      let formattedDate = item.done_date;
      if (formattedDate && formattedDate.includes("undefined")) {
        formattedDate = null;
      }

      this.editedItem = {
        ...item,
        done_date: formattedDate,
        release_type: { ...item.release_type },
      };
      this.dialog_edit = true;
    },
    saveEdit() {
      if (!this.editedItem.done && this.editedItem.done_date) {
        this.editedItem.done = false;
      }
      this.$emit("updateRelease", this.editedItem);
      this.closeEdit();
    },
    closeEdit() {
      this.dialog_edit = false;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
  },
};
</script>
